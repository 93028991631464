import styles from "./Services.module.scss";
import ImageSlider from "../Slider/Slider";
import useAnimate from "../../hooks/useAnimate";
import pool_prices from "../../assets/pool/pool_prices.png";
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';

const ServiceItem = ({
  icon,
  title,
  info,
  info_add,
  sliderImages,
  reversed,
}) => {
  const { ref, isAnimated } = useAnimate();
  return (
    <article
      ref={ref}
      id={title}
      className={`${styles.sliderRow} ${reversed ? styles.reverse : ""} 
       animated-element ${isAnimated ? "animate" : ""}`}
    >
      <div className={styles.textBlock}>
        <div
          className={`${styles.textBlock_H} ${reversed ? styles.reverse : ""}`}
        >
          <img src={icon}></img>
          <h2>{title}</h2>
          <span></span>
        </div> 
        <p className={`${styles.p} ${reversed ? styles.reversed_p : ""}`}>
          {info}
        </p>
        <p className={`${styles.p} ${reversed ? styles.reversed_p : ""}`}>
          {info_add}
        </p>
        {title === "Басейн" ? (
            <PhotoProvider>
              <PhotoView src={pool_prices}>
                <button className={styles.button}>Переглянути ціни</button>
              </PhotoView>
            </PhotoProvider>
        ) : null}
      </div>
      <ImageSlider
        images={sliderImages}
        id="slider-serv"
        width={550}
      ></ImageSlider>
    </article>
  );
};
export default ServiceItem;
